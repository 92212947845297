import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAnhTBMboP92O_SWy11sjNARcN64wEVId8",
    authDomain: "portfolio-site-33237.firebaseapp.com",
    projectId: "portfolio-site-33237",
    storageBucket: "portfolio-site-33237.appspot.com",
    messagingSenderId: "597604527295",
    appId: "1:597604527295:web:e1ae977f3a84f1ce5e6a0a",
    measurementId: "G-YNR8R68PWH"
  };

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
export { db };
