import React from 'react'
import Nav from './Components/Navbar/Nav'
import Hero from './Components/Hero/Hero'
import "./Main.css"
import { Projects } from './Components/Project/Projects'
import {  ProjectsContextProvider } from '../Lib/Context/Project'
import { TextContextProvider } from '../Lib/Context/TextContext'
import Skills from './Components/Skill/Skills'
import { SkilsContextProvider } from '../Lib/Context/Skils'
import WorkExperience from './Components/WorkExperience/WorkExperience'
import Footer from './Components/Footer/Footer'
import ContactInfo from './Components/Contact/ContactInfo'
import Data from '../Lib/FireBase/Data.js'
import { ExperienceContextProvider } from '../Lib/Context/Experience.js'
import { LinksContextProvider } from '../Lib/Context/Link.js'


function Main() {
  return (
    <LinksContextProvider>
      <ExperienceContextProvider>
      <SkilsContextProvider>
      <TextContextProvider>
      <ProjectsContextProvider>
        <div>
          <Nav />
          <div className="Container">
            <Hero />
            <Projects />
            <Skills/>
            <WorkExperience/>
          </div>
          <ContactInfo/>
          <Footer/>
          <Data/>
        </div>
      </ProjectsContextProvider>
    </TextContextProvider>
    </SkilsContextProvider>
    </ExperienceContextProvider>
    </LinksContextProvider>
  )
}

export default Main