import { createContext, useState } from 'react';
const LinksContext = createContext();

const LinksContextProvider = ({ children }) => {
  const [link, setLink] = useState([]);

  return (
    <LinksContext.Provider value={{ link, setLink }}>
      {children}
    </LinksContext.Provider>
  );
};

export { LinksContext, LinksContextProvider };