import React, { useContext } from 'react';
import './ContactInfo.css';  // CSS dosyasını içe aktarın
import ContactCard from './ContactCard/ContactCard';
import { LinksContext } from '../../../Lib/Context/Link';


const ContactInfo = () => {


  const {link} = useContext(LinksContext)
  const varLinks = Object.values(link)

  return (
    <>
       <div className="contact">
          <div className="contact-content">
            {varLinks.map((item)=>(
              <ContactCard
                key={item.title}
                logo={item.logo}
                link={item.link}
              />
            ))}
          </div>
       </div>
    </>
  )
}

export default ContactInfo;
