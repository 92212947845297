import { createContext, useState } from 'react';

const TextContext = createContext();

const TextContextProvider = ({ children }) => {
  const [text, setText] = useState({});

  return (
    <TextContext.Provider value={{ text, setText }}>
      {children}
    </TextContext.Provider>
  );
};

export { TextContext, TextContextProvider };
