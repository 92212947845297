import React, { useContext } from 'react'
import { ProjectsContext } from '../../../Lib/Context/Project'
import ProjectCards from './ProjectCards/ProjectCards'
import "./Project.css"

export const Projects = () => {
  const { project } = useContext(ProjectsContext)
  return (
    <>
      <section id='Projects' className="projects-container" style={{ marginTop: "10rem" }}>
        <h5>Projects</h5>
        <div className="projects-content">
          <div className="projects">
            {project.map((item) => (
              <ProjectCards
                key={item.title}
                iconURL={item.iconURL}
                title={item.title}
                imageURL={item.imageURL} />
            ))}
          </div>
          <div className="projects-info">
          </div>
        </div>
      </section>
    </>
  )
}
