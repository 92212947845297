import React from 'react'
import './ProjectCards.css'

function ProjectCards({ iconURL, title, isActive, onClick, imageURL }) {
  return (
    <>
     {/*<div className="project-title">
        <span>{title}</span>
  </div>*/}
    <div className={`project-card ${isActive ? "Active" : ""}`} style={{ backgroundImage: `url(${imageURL})`, backgroundSize: "cover", backgroundPosition: "center" }} onClick={onClick}>
      <div className="project-icon" >
        <img src={iconURL} alt={title} />
      </div>

    </div>
  </>)
}

export default ProjectCards
