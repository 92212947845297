import { createContext, useState } from 'react';

const SkilsContext = createContext();

const SkilsContextProvider = ({ children }) => {
  const [skill, setSkill] = useState([]);

  return (
    <SkilsContext.Provider value={{ skill, setSkill }}>
      {children}
    </SkilsContext.Provider>
  );
};

export { SkilsContext, SkilsContextProvider };