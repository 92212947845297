import React from 'react'
import "./ContactInfo.css"

const ContactCard = ({link,logo}) => {
  return (
    <div className="contact-card">
      <a href={link}  target='_blank'>
        <img className='contact-icon' src={logo} alt="" />
      </a>
    </div>
  )
}

export default ContactCard