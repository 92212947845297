import React, { useContext } from 'react';
import '../Navbar/MobileNav.css'
import { TextContext } from '../../../Lib/Context/TextContext';
import { Link } from "react-scroll";

export default function MobileNav({ isOpen, toggleMenu }) {

  const { text } = useContext(TextContext);
  const navText = Object.values(text);
  const link = "https://firebasestorage.googleapis.com/v0/b/portfolio-site-33237.appspot.com/o/CV%2F%C4%B0ngilizce%20CV.pdf?alt=media&token=0e7523af-15bc-4b3b-8284-a4f24c90e765"

  const navClose = () => {
    toggleMenu(); // Menü durumunu kapatmak için toggleMenu'yu çağırıyoruz
  };

  return (
    <div>
      <div className={`mobile-menu ${isOpen ? "active" : ""}`} onClick={toggleMenu}>
        <div className='mobile-menu-container'>
          <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-site-33237.appspot.com/o/Images%2FLogo%20Yeni.png?alt=media&token=5e24d1f4-d12b-4fb3-adc5-6c33d9cb2989" style={{ width: "150px", height: "50px" }} alt="" />
          <ul>
            {navText.map((item)=>(
              <li key={item.title}>
              <Link to={item.title} smooth={true} duration={500} offset={-200} className="menu-item">
                {item.title}
              </Link>
            </li>
            ))}
            <a className='cv-link' href={link}  target='_blank'>
            <button  className='container-btn' onClick={() => { }}>
              Download CV
            </button>
            </a>
          </ul>
        </div>
      </div>
    </div>
  );
}
