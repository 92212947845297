import React, { useContext, useRef } from 'react'
import "./WorkExperience.css"
import ExperienceCard from './ExperienceCard/ExperienceCard'
import Slider from 'react-slick'
import { ExperienceContext } from '../../../Lib/Context/Experience'


const WorkExperience = () => {

  const {experience} = useContext(ExperienceContext);
  const EXPERIENCE = Object.values(experience)

  const sliderRef = useRef();
  const settings = {
    dots:false,
    infinite:true,
    speed:500,
    slidesToShow:2,
    slidesToScroll: 1,
    arrows:false,
    responsive:[
     {
      breakpoint:769,
      settings:{
        slidesToShow:1,
        slidesToScroll: 1,
      },
     }
    ],
  }
  return (
    <section id='Experience' className="experience-container">
        <h5>
            Work Experience
        </h5>
        <div className="experience-content">

         <Slider ref={sliderRef} {...settings}>
         {
          EXPERIENCE.map((varItem)=>(
            <ExperienceCard key={varItem.title} details={varItem}/>
          ))
         }
         </Slider>
        </div>
    </section>
  )
}

export default WorkExperience