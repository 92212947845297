import { createContext, useState } from 'react';
const ProjectsContext = createContext();

const ProjectsContextProvider = ({ children }) => {
  const [project, setProject] = useState([]);

  return (
    <ProjectsContext.Provider value={{ project, setProject }}>
      {children}
    </ProjectsContext.Provider>
  );
};

export { ProjectsContext, ProjectsContextProvider };