import React, { useContext, useEffect, useState } from 'react';
import "./Skills.css";
import SkillCard from './SkilllCard/SkillCard';
import SkillInfoCard from './SkillsInfoCard/SkillInfoCard.jsx';
import { SkilsContext } from '../../../Lib/Context/Skils.js';
import { STARTDATA } from '../../../Lib/FireBase/StartData.js';

function Skills() {
    const { skill } = useContext(SkilsContext);
    const SKİLL = Object.values(skill);
    const [selectedSkill, setSelectedSkill] = useState(null);

    useEffect(() => {
        if (SKİLL.length > 0 && !selectedSkill) {
            setSelectedSkill(SKİLL[0]);
        }
    }, [SKİLL, selectedSkill]);

    const handleSelectedSkill = (data) => {
        setSelectedSkill(data);
    }

    return (
        <section id='Skills' className="skills-container">
            <h5>Skills</h5>
            <div className="skills-content">
                <div className="skills">
                    {SKİLL.map((item) => (
                        <SkillCard
                            key={item.title}
                            iconUrl={item.icon}
                            title={item.title}
                            isActive={selectedSkill && selectedSkill.title === item.title}
                            onClick={() => {
                                handleSelectedSkill(item);
                            }}
                        />
                    ))}
                </div>
                <div className="skills-info">
                    {selectedSkill && (
                        <SkillInfoCard
                            heading={selectedSkill.title}
                            skills={selectedSkill.skills}
                        />
                    )}
                </div>
            </div>
        </section>
    );
}

export default Skills;
