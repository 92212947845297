import React from 'react';
import './Footer.css'; // Eğer Footer için ek bir CSS dosyanız varsa ekleyin

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-content">
          <p>© 2024 Osman Serin Portfolio Site</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
