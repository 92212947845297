import React, { useState, useEffect, useContext } from 'react';
import { db } from './SDK';
import { collection, onSnapshot } from 'firebase/firestore';
import { ProjectsContext } from '../Context/Project';
import { SkilsContext } from '../Context/Skils';
import { TextContext } from '../Context/TextContext';
import { ExperienceContext } from '../Context/Experience';
import { LinksContext } from '../Context/Link';

const Data = () => {
    const {setProject} = useContext(ProjectsContext)
    const {setSkill} = useContext(SkilsContext)
    const {setText} = useContext(TextContext)
    const {setExperience} = useContext(ExperienceContext)
    const {setLink,link} = useContext(LinksContext)


    //Project
    useEffect(() => {
        try{
            const unsubscribe = onSnapshot(collection(db, "Project"), (snapshot) => {
                const projectData = [];
                snapshot.forEach((doc) => {
                    projectData.push({ id: doc.id, ...doc.data() });
                });
                setProject(projectData)
            });
            return () => unsubscribe();
        }
        catch(err){
            console.log("Data.js de project koleksiyonu çekilemedi. Hata kodu aşşağıdadır");
            console.log(err)
        }
    }, []);

    //Skills
    useEffect(() => {
        try{
            const unsubscribe = onSnapshot(collection(db, "Skills"), (snapshot) => {
                const colSkills = [];
                snapshot.forEach((doc) => {
                    colSkills.push({ id: doc.id, ...doc.data() });
                });
                setSkill(colSkills);

            });
            return () => unsubscribe();
        }
        catch(err){
            console.log("Data.js de skills koleksiyonu çekilemedi. Hata kodu aşşağıdadır");
            console.log(err)
        }
    }, []);

     //NavItems
     useEffect(() => {
        try{
            const unsubscribe = onSnapshot(collection(db, "Navbar"), (snapshot) => {
                const colNavbar = [];
                snapshot.forEach((doc) => {
                    colNavbar.push({ id: doc.id, ...doc.data() });
                });
                setText(colNavbar);
            });
            return () => unsubscribe();
        }
        catch(err){
            console.log("Data.js de Navbar koleksiyonu çekilemedi. Hata kodu aşşağıdadır");
            console.log(err)
        }
    }, []);

     //Experience
     useEffect(() => {
        try{
            const unsubscribe = onSnapshot(collection(db, "Experience"),(snapshot) => {
                const colExperience = [];
                snapshot.forEach((doc) => {
                    colExperience.push({ id: doc.id, ...doc.data() });
                });
                setExperience(colExperience)
            });
            return () => unsubscribe();
        }
        catch(err){
            console.log("Data.js de experience koleksiyonu çekilemedi. Hata kodu aşşağıdadır");
            console.log(err)
        }
    }, []);

     //Links
     useEffect(() => {
        try{
            const unsubscribe = onSnapshot(collection(db, "Links"),(snapshot) => {
                const colLinks = [];
                snapshot.forEach((doc) => {
                    colLinks.push({ id: doc.id, ...doc.data() });
                });

                setLink(colLinks)
            });
            return () => unsubscribe();
        }
        catch(err){
            console.log("Data.js de Links koleksiyonu çekilemedi. Hata kodu aşşağıdadır");
            console.log(err)
        }
    }, []);

}

export default Data;

