import React, { useContext, useEffect, useState } from 'react';
import '../Navbar/Nav.css';
import MobileNav from './MobileNav';
import { TextContext } from '../../../Lib/Context/TextContext';
import { Link } from "react-scroll";

export default function Nav() {

  const [openMenu, setOpenMenu] = useState(false);
  const [item, setItem] = useState([]);

  const link = "https://firebasestorage.googleapis.com/v0/b/portfolio-site-33237.appspot.com/o/CV%2F%C4%B0ngilizce%20CV.pdf?alt=media&token=0e7523af-15bc-4b3b-8284-a4f24c90e765"

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const { text } = useContext(TextContext);

  const navText = Object.values(text);

  return (
    <>
      <MobileNav isOpen={openMenu} toggleMenu={toggleMenu} />

      <nav className='nav-wrapper'>
        <div className="nav-content">
          <img className='logo' src="https://firebasestorage.googleapis.com/v0/b/portfolio-site-33237.appspot.com/o/Images%2FLogo%20Yeni.png?alt=media&token=5e24d1f4-d12b-4fb3-adc5-6c33d9cb2989" alt="" />
          <ul>
            {navText.map((item)=>(
              <li key={item.title}>
              <Link to={item.title} smooth={true} duration={500} offset={-200} className="menu-item">
                {item.title}
              </Link>
            </li>
            ))}
            <a className='cv-link' href={link}  target='_blank'>
            <button  className='container-btn' onClick={() => { }}>
              Download CV
            </button>
            </a>
          </ul>

          <button className='menu-btn' onClick={toggleMenu}>
            <span className='material-symbols-outlined' style={{ fontSize: "1.8rem" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
              </svg>
            </span>
          </button>
        </div>
      </nav>
    </>
  );
}
