import React from 'react';
import "./SkillsInfoCard.css";

const SkillsInfoCard = ({ skills,heading }) => {

  const skillItems = Object.values(skills)

  return (
    <div className="skills-info-card">
      <h4>{heading}</h4>
      {skillItems.map((skill, index) => (
        <div className="skills-info-content">
          <React.Fragment key={`skill_${index}`}>
          <div className="skill-info">
            <p>{skill.Language}</p>
            <p className="percentage">
              {skill.percentage}
            </p>
          </div>

          <div className="skill-progress-bg">
            <div className="skill-progress" style={{width:skill.percentage}}></div>
          </div>
         </React.Fragment>
        </div>
      ))}
    </div>
  );
}

export default SkillsInfoCard;
