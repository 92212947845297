import { createContext, useState } from 'react';

const ExperienceContext = createContext();

const ExperienceContextProvider = ({ children }) => {
  const [experience, setExperience] = useState([]);

  return (
    <ExperienceContext.Provider value={{ experience, setExperience }}>
      {children}
    </ExperienceContext.Provider>
  );
};

export { ExperienceContext, ExperienceContextProvider };