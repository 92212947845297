import React from 'react'
import "./Hero.css"
import react from "../../../Lib/Images/React Logo.png"
import python from "../../../Lib/Images/Python.png"
import powerapps from "../../../Lib/Images/Power Apps Logo.png"
import powerautomate from "../../../Lib/Images/Power automate image.png"
import powerBI from "../../../Lib/Images/Power BI Logo.png"

const Hero = () => {
    return (
        <section id='Home' className='hero-container'>
            <div>
                <h2>Building Tomorrow's Digital Solutions with React and Power Platform</h2>
                <p>Hello! I'm Osman, a React and Power Platform specialist. I develop modern and user-friendly web applications. Check out my portfolio!</p>
            </div>
            <div className="hero-img">
                <div>
                    <div className="tech-icon">
                        <img src={react} alt="" />
                    </div>
                    <img className='hero' src="https://firebasestorage.googleapis.com/v0/b/portfolio-site-33237.appspot.com/o/Images%2FWhatsApp%20G%C3%B6rsel%202024-05-28%20saat%2018.00.20_8bdc71b4.jpg?alt=media&token=7efcd36c-f363-4448-b6ee-d6ef183c4d30" alt="" />
                </div>

                <div>
                    <div className="tech-icon">
                        <img src={python} alt="" />
                    </div>
                    <div className="tech-icon">
                        <img src={powerapps} alt="" />
                    </div>
                    <div className="tech-icon">
                        <img src={powerautomate} alt="" />
                    </div>
                    <div className="tech-icon">
                        <img src={powerBI} alt="" />
                    </div>

                </div>

            </div>
        </section>
    )
}

export default Hero
