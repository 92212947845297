import './App.css';
import Main from './App/Main';
import Data from './Lib/FireBase/Data';

function App() {
  return (
    <Main/>
  );
}

export default App;

